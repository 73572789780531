<template>
  <div class="privacy-policy">
    <h1 class="mb-3"> <strong>Privacy Policy for AFP Application</strong></h1>

    <section>
      <h2>1. Information Collection</h2>
      <p>We may collect personal information from Users in various ways, including but not limited to when Users visit our application, register, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or engage in other activities on our application. The personal information we collect may include, but is not limited to:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Mailing address</li>
        <li>Phone number</li>
        <li>Date of birth</li>
        <li>Credit card information</li>
      </ul>
      <p>We may also collect non-personal information about Users whenever they interact with our application. This non-personal information may include the browser name, type of computer or device, and technical information about Users' means of connection to our application, such as the operating system and the Internet service providers utilized.</p>
    </section>

    <section>
      <h2>2. Information Usage</h2>
      <p>We collect and use Users' personal information for the following purposes:</p>
      <ul>
        <li>To improve customer service: Information provided helps us respond to customer service requests and support needs more efficiently.</li>
        <li>To personalize User experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our application.</li>
        <li>To process transactions: We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.</li>
        <li>To send periodic emails: We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests. If the User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</li>
      </ul>
    </section>

    <section>
      <h2>3. Information Protection</h2>
      <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information, username, password, transaction information, and data stored on our application. Sensitive and private data exchange between the application and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.</p>
    </section>

    <section>
      <h2>4. Sharing Personal Information</h2>
      <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and Users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
    </section>

    <section>
      <h2>5. NOC Application and Room Reservation</h2>
      <p>Users can apply for NOC and room reservation through the AFP Application. Serving personnel and entitled individuals can apply for NOC and room reservation through their respective channels. Retired personnel and serving personnel not eligible for entitled services can apply directly through the application. For any queries or assistance regarding NOC application and room reservation, Users can contact our customer support team.</p>
    </section>

    <section>
      <h2>6. Compliance with Legal Obligations</h2>
      <p>We may disclose personal information when required by law or in response to valid legal process, such as a subpoena, court order, or other governmental request. We may also share information when we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law.</p>
    </section>

    <section>
      <h2>7. Changes to this Privacy Policy</h2>
      <p>AFP Application has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. Users acknowledge and agree that it is their responsibility to review this privacy policy periodically and become aware of modifications.</p>
    </section>

    <section>
      <h2>8. Your Acceptance of These Terms</h2>
      <p>By using this application, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our application. Your continued use of the application following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
    </section>

    <section>
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, the practices of this application, or your dealings with this application, please <a href="#" @click="openGmailCompose"><strong>contact us</strong></a>.</p>
    </section>

    <p class="last-updated">This Privacy Policy was last updated on 3/22/2024.</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
    methods: {
    openGmailCompose(){ 
     window.open('https://mail.google.com/mail/?view=cm&fs=1&to=postmaster@afp.gov.pk', '_blank');
    }
  },
  computed: {
  },
};
</script>
<style>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bolder;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy a {
  color: #007bff;
}

.last-updated {
  margin-top: 20px;
  font-style: italic;
}   
</style>